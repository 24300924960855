import React from 'react'
import styled from 'styled-components'
import TextField from '../../components/TextField'
import LockIcon from '@material-ui/icons/Lock'
import { MenuItem, Grid, Checkbox, FormControlLabel } from '@material-ui/core'
import regions, { regionsON } from '../../constants/regions'
import CriminalCase from './criminalCase'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const AddInfo = styled.span`
    display: block;
    font-size: 0.85rem;
    font-weight: 600;
    margin-bottom: 5px;
`

const AddInfoDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    span {
        font-size: 0.85rem;
        font-weight: 600;
    }
`

const Info = ({ saveFieldState, fields }) => {
    const { caseDescription } = fields
    const { t } = useTranslation()
    return (
        <div style={{ width: '100%' }}>
            <Grid item xs={12} sm={10} style={{ margin: '0 auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <AddInfoDiv>
                            <LockIcon style={{ color: '#48cb48' }} />
                            <span>
                                <Trans>
                                    100% CONFIDENTIEL, vous pouvez être bref
                                </Trans>
                                .
                            </span>
                        </AddInfoDiv>
                        <TextField
                            fullWidth
                            helperText={t(
                                'Veuillez expliquer votre cas brièvement.'
                            )}
                            id="caseDescription"
                            name="caseDescription"
                            className="textInput"
                            label={t(
                                'Décrivez brièvement votre dossier/situation'
                            )}
                            autoComplete="off"
                            onChange={saveFieldState}
                            multiline={true}
                            rows={5}
                            {...caseDescription}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Info
