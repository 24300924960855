import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import currency from '../../utils/currency'

import {
    Grid,
    Select,
    MenuItem,
    Input,
    FormControl,
    InputLabel,
    Button as MaterialButton,
} from '@material-ui/core'
import revenueRanges from '../../constants/revenueRanges'
import TextField from '../../components/TextField'
import ButtonRow from '../../components/ButtonRow'

const Button = styled(MaterialButton)`
    && {
        height: 50px;
        border-radius: 30px;
        width: 100%;
        .MuiFormControl {
            margin-top: 10px;
        }
    }
`

const statuses = {
    QC: {
        'Personne seule': 25935,
        'Personne seule et 1 enfant à charge': 31733,
        'Personne seule et 2 enfants ou plus à charge': 33875,
        Conjoints: 36095,
        'Conjoints et 1 enfant à charge': 40385,
        'Conjoints et 2 enfants ou plus à charge': 42530,
    },
    ON: {
        '1 personne': 22720,
        '2 personnes': 32131,
        '3 personnes': 39352,
        '4 personnes': 45440,
        '5+ personnes': 50803,
    },
}

const juriPopStatuses = {
    'Personne seule': 41664,
    'Personne seule et 1 enfant à charge': 50964,
    'Personne seule et 2 enfants ou plus à charge': 54406,
    Conjoints: 57980,
    'Conjoints et 1 enfant à charge': 64869,
    'Conjoints et 2 enfants ou plus à charge': 68316,
}

const showAmountsButtons = (province, revenueRange, status) => {
    if (province !== 'QC' || revenueRange > 3) {
        return false
    }
    if (revenueRange === 1) {
        return true
    }
    if (
        revenueRange === 2 &&
        juriPopStatuses[status] >= 51000 &&
        juriPopStatuses[status] <= 64999
    ) {
        return true
    }
    if (revenueRange === 3 && juriPopStatuses[status] === 68316) {
        return true
    }
    return false
}

const JuridicalHelp = ({
    values,
    handleChange,
    saveFieldState,
    province = 'QC',
    simple,
}) => {
    const {
        thinks,
        hasLowRevenue,
        status,
        revenueRange,
        privateLawyer,
        showJuriPop,
        discountEligible,
    } = values
    const { t, i18n } = useTranslation()
    const locale = i18n.language === 'en' ? 'en-CA' : 'fr-CA'
    const [simpleAJ, setSimpleAJ] = useState(undefined)
    const handleSimpleAJChange = (_, value) => {
        setSimpleAJ(value)
        if (value === 'yes') {
            handleChange('revenueRange', 1)
            handleChange('hasLowRevenue', true)
            handleChange('thinks', 'yes')
        }
        if (value === 'no') {
            handleChange('revenueRange', 0)
            handleChange('hasLowRevenue', false)
            handleChange('thinks', 'no')
        }
    }
    return simple ? (
        <Grid item xs={12} className="legal-aid-section">
            <span
                style={{
                    display: 'block',
                    fontSize: '0.95rem',
                    marginBottom: '0.2rem',
                }}
            >
                Êtes-vous en état de pauvreté vous permettant d’avoir accès à
                l’aide juridique?
            </span>
            <ButtonRow
                value={simpleAJ}
                saveFieldState={handleSimpleAJChange}
                options={[
                    { value: 'yes', label: 'Oui' },
                    { value: 'no', label: 'Non' },
                ]}
            />
            <small
                style={{
                    display: 'block',
                    fontSize: '0.75rem',
                    marginTop: '0.2rem',
                }}
            >
                (Information requise si vous voulez l'aide juridique)
            </small>
        </Grid>
    ) : (
        <Grid container spacing={2} className="legal-aid-section">
            <Grid item xs={12} sm={10} style={{ margin: '0 auto' }}>
                <Grid item xs={12}>
                    <TextField
                        select
                        fullWidth
                        helperText={t('Vous devez sélectionner une province.')}
                        id="province"
                        name="province"
                        className="textInput"
                        label={t('Votre province')}
                        autoComplete="off"
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            height: '70px',
                        }}
                        onChange={saveFieldState}
                        {...province}
                    >
                        <MenuItem key={'QC'} value={'QC'}>
                            Québec
                        </MenuItem>
                        <MenuItem key={'ON'} value={'ON'}>
                            Ontario
                        </MenuItem>
                    </TextField>
                    <TextField
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            height: '70px',
                            marginBottom: '10px',
                        }}
                        select
                        fullWidth
                        id="revenueRange"
                        name="revenueRange"
                        className="textInput"
                        label={t('Votre revenu')}
                        autoComplete="off"
                        value={revenueRange}
                        onChange={(name, value) => handleChange(name, value)}
                    >
                        {revenueRanges.map((label, i) => (
                            <MenuItem key={label} value={i}>
                                {t(label)}
                            </MenuItem>
                        ))}
                    </TextField>

                    {statuses[province.value] &&
                        revenueRange < 4 &&
                        revenueRange > 0 && (
                            <Fragment>
                                {' '}
                                <Grid item xs={12}>
                                    <TextField
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                            height: '70px',
                                        }}
                                        select
                                        fullWidth
                                        id="status"
                                        name="status"
                                        className="textInput"
                                        label={t(
                                            'Quelle situation familial vous représente le mieux?'
                                        )}
                                        autoComplete="off"
                                        value={status}
                                        onChange={(name, value) =>
                                            handleChange(name, value)
                                        }
                                    >
                                        {Object.keys(
                                            statuses[province.value]
                                        ).map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {t(item)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {statuses[province.value][status] &&
                                showAmountsButtons(
                                    province.value,
                                    revenueRange,
                                    status
                                ) ? (
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ textAlign: 'left' }}
                                    >
                                        <span>
                                            <Trans>
                                                Quel montant approximatif
                                                représente le mieux votre revenu
                                                familial
                                            </Trans>
                                        </span>
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{ marginTop: '5px' }}
                                        >
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={() =>
                                                        handleChange(
                                                            'hasLowRevenue',
                                                            true
                                                        )
                                                    }
                                                    color={
                                                        hasLowRevenue
                                                            ? 'secondary'
                                                            : 'primary'
                                                    }
                                                    variant="contained"
                                                >
                                                    <Trans>Moins de</Trans>{' '}
                                                    {currency(
                                                        statuses[
                                                            province.value
                                                        ][status] * 100,
                                                        locale
                                                    )}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={() =>
                                                        handleChange(
                                                            'discountEligible',
                                                            true
                                                        )
                                                    }
                                                    color={
                                                        discountEligible
                                                            ? 'secondary'
                                                            : 'primary'
                                                    }
                                                    variant="contained"
                                                >
                                                    <Trans>Moins de</Trans>{' '}
                                                    {currency(
                                                        juriPopStatuses[
                                                            status
                                                        ] * 100,
                                                        locale
                                                    )}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    onClick={() =>
                                                        handleChange(
                                                            'discountEligible',
                                                            false
                                                        )
                                                    }
                                                    color={
                                                        discountEligible ===
                                                        false
                                                            ? 'secondary'
                                                            : 'primary'
                                                    }
                                                    variant="contained"
                                                >
                                                    {currency(
                                                        juriPopStatuses[
                                                            status
                                                        ] * 100,
                                                        locale
                                                    )}{' '}
                                                    <Trans>ou plus</Trans>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    statuses[province.value][status] &&
                                    revenueRange === 1 && (
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ textAlign: 'left' }}
                                        >
                                            <span>
                                                <Trans>
                                                    Mon revenue est inférieur à
                                                </Trans>{' '}
                                                {currency(
                                                    statuses[province.value][
                                                        status
                                                    ] * 100,
                                                    locale
                                                )}
                                            </span>
                                            <Grid
                                                container
                                                spacing={2}
                                                style={{ marginTop: '5px' }}
                                            >
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={() =>
                                                            handleChange(
                                                                'hasLowRevenue',
                                                                true
                                                            )
                                                        }
                                                        color={
                                                            hasLowRevenue
                                                                ? 'secondary'
                                                                : 'primary'
                                                        }
                                                        variant="contained"
                                                    >
                                                        <Trans>Oui</Trans>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        onClick={() =>
                                                            handleChange(
                                                                'hasLowRevenue',
                                                                false
                                                            )
                                                        }
                                                        color={
                                                            hasLowRevenue ===
                                                            false
                                                                ? 'secondary'
                                                                : 'primary'
                                                        }
                                                        variant="contained"
                                                    >
                                                        <Trans>Non</Trans>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                            </Fragment>
                        )}
                </Grid>

                {revenueRange > 1 || hasLowRevenue === false ? (
                    <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        style={{ marginTop: '10px' }}
                    >
                        <Grid item xs={12}>
                            <strong>{t('notAdmissibleToLegalAid')}</strong>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color={privateLawyer ? 'secondary' : 'primary'}
                                onClick={() =>
                                    handleChange('privateLawyer', true)
                                }
                            >
                                <Trans>Je veux un avocat privé</Trans>
                            </Button>
                        </Grid> */}
                    </Grid>
                ) : null}

                {revenueRange <= 1 && hasLowRevenue ? (
                    <Grid item xs={12}>
                        <p>
                            <strong>
                                <Trans>
                                    Voulez-vous un avocat d'aide juridique
                                </Trans>
                                ?
                            </strong>
                        </p>
                        {/*province.value === 'QC' ? (
                            <p style={{ fontWeight: 600, color: 'red' }}>
                                <Trans i18nKey="legalAidWarning">
                                    ATTENTION! Plus de 95% de nos avocats en
                                    Aide Juridique n'ont plus de place
                                    disponible et ne prennent plus de nouveaux
                                    client... Ce qui signifie que vous avez de
                                    très forte chance que personne ne puisse
                                    répondre à votre demande en choisissant
                                    cette option. Nous recommandons de
                                    sélectionner un avocat privé.
                                </Trans>
                            </p>
                        ) : null */}
                        <Grid
                            container
                            spacing={2}
                            style={{ marginBottom: '20px' }}
                        >
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color={
                                        privateLawyer === false
                                            ? 'secondary'
                                            : 'default'
                                    }
                                    onClick={() =>
                                        handleChange('privateLawyer', false)
                                    }
                                >
                                    <Trans>Je veux l'aide juridique</Trans>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color={
                                        privateLawyer ? 'secondary' : 'primary'
                                    }
                                    onClick={() =>
                                        handleChange('privateLawyer', true)
                                    }
                                >
                                    <Trans>Je veux un avocat privé</Trans>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    )
}

export default JuridicalHelp
