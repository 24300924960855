import React from 'react'
import TextField from '../../components/TextField'
import { MenuItem, Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import regions, { regionsON } from '../../constants/regions'

const nonDigits = new RegExp(/[^0-9\-\s]/g)

const maskPhoneNumber = (phoneNumber) => phoneNumber.replace(nonDigits, '')

const Info = ({ saveFieldState, fields }) => {
    const { clientName, email, phoneNumber, province, region, acceptDistance } =
        fields
    const { t } = useTranslation()
    const regionsValues = province.value === 'ON' ? regionsON : regions

    return (
        <div style={{ width: '100%' }}>
            <Grid item xs={12} sm={10} style={{ margin: '0 auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            helperText={t('validName')}
                            id="clientName"
                            name="clientName"
                            className="textInput"
                            label={t('Nom complet')}
                            autoComplete="name"
                            onChange={saveFieldState}
                            {...clientName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            helperText={t('validEmail')}
                            id="email"
                            name="email"
                            className="textInput"
                            label={t('Courriel')}
                            autoComplete="email"
                            type="email"
                            onChange={saveFieldState}
                            {...email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            helperText={t('validPhoneNumber')}
                            id="phoneNumber"
                            name="phoneNumber"
                            className="textInput"
                            label={t('Numéro de téléphone')}
                            autoComplete="tel-national"
                            onChange={saveFieldState}
                            maskingFn={maskPhoneNumber}
                            {...phoneNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                        <TextField
                            select
                            fullWidth
                            helperText={t(
                                'Vous devez sélectionner une province.'
                            )}
                            id="province"
                            name="province"
                            className="textInput"
                            label={t('Province')}
                            autoComplete="off"
                            onChange={saveFieldState}
                            {...province}
                        >
                            <MenuItem key={'QC'} value={'QC'}>
                                Québec
                            </MenuItem>
                            <MenuItem key={'ON'} value={'ON'}>
                                Ontario
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={acceptDistance.value}
                                    onChange={() => {
                                        saveFieldState(
                                            'acceptDistance',
                                            !acceptDistance.value,
                                            true
                                        )
                                    }}
                                    name="acceptDistance"
                                />
                            }
                            label={
                                <span>
                                    <strong>
                                        <Trans>
                                            J'accepte d'être servi à distance,
                                            même à l'extérieur de ma région
                                        </Trans>
                                    </strong>{' '}
                                    <br />
                                    <small>{t('optionIncreaseChances')}</small>
                                </span>
                            }
                        />
                    </Grid>

                    {province.value !== '' ? (
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <TextField
                                select
                                fullWidth
                                helperText={t(
                                    'Vous devez sélectionner une région.'
                                )}
                                id="region"
                                name="region"
                                className="textInput"
                                style={{ maxWidth: '88vw' }}
                                label={
                                    acceptDistance.value === true
                                        ? t("Région souhaitée pour l'avocat")
                                        : t('Votre région')
                                }
                                autoComplete="off"
                                onChange={saveFieldState}
                                {...region}
                            >
                                {regionsValues.map((item) => (
                                    <MenuItem key={item.name} value={item.name}>
                                        {t(item.label)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </div>
    )
}

export default Info
