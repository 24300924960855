export function getCookie(name) {
    const cookieArr = document.cookie.split(';')
    for (let i = 0; i < cookieArr.length; i++) {
        const [cookieName, value] = cookieArr[i].split('=')
        if (cookieName.trim() === name) {
            return value.trim()
        }
    }
    return ''
}

export function setCookie(name, value, expires) {
    if (!expires) {
        expires = new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24 * 365
        ).toGMTString()
    }
    const hostnameArr = window.location.hostname.split('.')
    let domain = '.' + window.location.hostname
    if (hostnameArr.length > 2) {
        domain = `.${hostnameArr[hostnameArr.length - 2]}.${
            hostnameArr[hostnameArr.length - 1]
        }`
    }
    document.cookie = `${name}=${value}; expires=${expires}; Domain=${domain}; Secure; SameSite=None;`
}
